@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
:root{
    /* --theme: #1e7b9c; */
    /* --theme: #116b6f; */
    --theme: #1fc1c7;
    --theme-light: #64e3e7;
    --theme-lighter: #e9fbfc;
}
body{
    font-family: "Roboto", sans-serif !important;
}
.heading{

}
.subheading{
    color: #757575;
}
.bg-custom{
   background-color: #5CE1E6 !important;
}
.bg-theme-light{
   background-color: var(--theme-light) !important;
}
.text-custom{
   color: #5CE1E6 !important;
}
.bg-custom-light{
   background-color: #E1FAFFbf !important;
}
.bg-custom-light-t{
   background-color: #E1FAFFbf !important;
}
.fs-7{
    font-size: 0.90rem !important;
}
.fs-8{
    font-size: 0.80rem !important;
}
.fs-9{
    font-size: 0.70rem !important;
}
.fs-10{
    font-size: 0.60rem !important;
}
.w-fit{
    width: fit-content !important;
}
.w-max{
    width: max-content !important;
}
.w-min{
    width: min-content !important;
}

.h-fit{
    height: fit-content !important;
}
.h-max{
    height: max-content !important;
}
.h-min{
    height: min-content !important;
}
.py-2-5 {
    padding-top: .8rem !important;
    padding-bottom: .8rem !important;
}
.text-theme{
    color: var(--theme) !important;
}
.bg-theme{
    background-color: var(--theme) !important;
}
.btn-theme{
    --bs-btn-color: #ffffff !important;
    --bs-btn-bg: var(--theme) !important;
    --bs-btn-border-color: var(--theme-light) !important;
    --bs-btn-hover-color: #ffffff !important;
    --bs-btn-hover-bg: var(--theme-light) !important;
    --bs-btn-hover-border-color: var(--theme) !important;
    --bs-btn-focus-shadow-rgb: 11, 172, 204 !important;
    --bs-btn-active-color: #ffffff !important;
    --bs-btn-active-bg: var(--theme) !important;
    --bs-btn-active-border-color: var(--theme) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff !important;
    --bs-btn-disabled-bg: var(--theme) !important;
    --bs-btn-disabled-border-color: var(--theme) !important;
}
.btn-facebook{
    --bs-btn-color: #fff !important;
    --bs-btn-bg: #3b5998 !important;
    --bs-btn-border-color: #f2f2f2 !important;
    --bs-btn-hover-color: #ffffff !important;
    --bs-btn-hover-bg: #2b447b !important;
    --bs-btn-hover-border-color: #3b5998 !important;
    --bs-btn-focus-shadow-rgb: 11, 172, 204 !important;
    --bs-btn-active-color: #ffffff !important;
    --bs-btn-active-bg: #3b5998 !important;
    --bs-btn-active-border-color: #3b5998 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff !important;
    --bs-btn-disabled-bg: #3b5998 !important;
    --bs-btn-disabled-border-color: #3b5998 !important;
}
.btn-google{
    --bs-btn-color: #fff !important;
    --bs-btn-bg: #EA4335	 !important;
    --bs-btn-border-color: #f2f2f2 !important;
    --bs-btn-hover-color: #ffffff !important;
    --bs-btn-hover-bg: #d33628	 !important;
    --bs-btn-hover-border-color: #EA4335 !important;
    --bs-btn-focus-shadow-rgb: 11, 172, 204 !important;
    --bs-btn-active-color: #ffffff !important;
    --bs-btn-active-bg: #EA4335 !important;
    --bs-btn-active-border-color: #EA4335 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff !important;
    --bs-btn-disabled-bg: #EA4335 !important;
    --bs-btn-disabled-border-color: #EA4335 !important;
}
.accordion {
    --bs-accordion-color: var(--bs-body-color);
    --bs-accordion-bg: var(--bs-body-bg);
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: var(--bs-border-width);
    --bs-accordion-border-radius: var(--bs-border-radius);
    --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) -(var(--bs-border-width)));
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23212529%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e);
    --bs-accordion-btn-icon-width: 1rem !important;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url('../public/chevron-up-solid.svg') !important;
    /* --bs-accordion-btn-active-icon: url(data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>) !important; */
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem transparent !important;
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: #fff !important;
    --bs-accordion-active-bg: var(--theme) !important;
}
.accordion-button{
    color: var(--theme) !important;
}
.focus-none:focus, .form-control:focus , .form-select:focus {
    border-color: none !important;
    outline: none !important;
    box-shadow: none !important;
}
.form-check-input:checked {
    background-color: #000000 !important;
    border-color: #000000 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.form-control, .form-select{
    font-size: 0.85rem !important;
}

.form-label.text-secondary {
    --bs-text-opacity: 1;
    color: #818181 !important;
}
.grid-col{
    display: grid;
    grid-template-rows: 40px calc(100% - 40px);
    height: calc(100vh - 75px);
}
.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color) !important;
    background-color: var(--bs-accordion-active-bg) !important;
}
.btn-outline-theme {
    --bs-btn-color: var(--theme) !important;
    --bs-btn-border-color: var(--theme) !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: var(--theme) !important;
    --bs-btn-hover-border-color: var(--theme) !important;
    --bs-btn-focus-shadow-rgb: 13, 202, 240 !important;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: var(--theme) !important;
    --bs-btn-active-border-color: var(--theme) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: var(--theme) !important;
    --bs-btn-disabled-bg: transparent !important;
    --bs-btn-disabled-border-color: var(--theme) !important;
    --bs-gradient: none !important;
}

.btn-secondary-light {
    --bs-btn-color: #fff;
    --bs-btn-bg: #eee !important;
    --bs-btn-border-color: #eee !important;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #a7a7a7 !important;
    --bs-btn-hover-border-color: #a7a7a7 !important;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #a7a7a7 !important;
    --bs-btn-active-border-color: #eee !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #eee !important;
    --bs-btn-disabled-border-color: #eee !important;
}
.border-theme {
    --bs-border-opacity: 1;
    border-color: var(--theme) !important;
}
.bg-light-theme {
    --bs-bg-opacity: 1;
    background-color: #EFFBFE !important;
}
.carousel-caption-align{
    top: 50% !important;
    transform: translate(0 , -50%) !important;
    left: 40px !important;
    text-align: start !important;
    vertical-align: middle !important;
    height: fit-content;
}
.no-scrollbar::-webkit-scrollbar{
    width: 0;
    height: 0;
}
.kep-login-facebook{
    padding: 8px 10px !important;
    width: 100%;
    text-transform: revert !important;
    border-radius: 3px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    display: grid !important;
    grid-template-columns: 25px auto;
    align-items: center;
}
.kep-login-facebook .fa{
    font-size: 16px !important;
}
.placeholder-light::placeholder{
    color: #adadad !important;
}
.menu-list {
    color: #757575;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
}
.menu-list.active , .menu-list.active:hover , .menu-list.active:hover .mf{
    background: var(--theme);
    color: #fff;
}
.menu-list:hover{
    background-color: var(--theme-lighter);
    cursor: pointer;
    color: #000;
}
.menu-list:hover .mf{
    color: var(--theme);
}
.cursor-pointer{
    cursor: pointer;
}
.border-dashed{
    --bs-border-style: dashed !important;
}
.border-dashed-hover:hover{

}
.pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-color: var(--theme) !important;
    --bs-pagination-bg: var(--bs-body-bg);
    --bs-pagination-border-width: var(--bs-border-width);
    --bs-pagination-border-color: var(--bs-border-color);
    --bs-pagination-border-radius: var(--bs-border-radius);
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: var(--bs-tertiary-bg);
    --bs-pagination-hover-border-color: var(--bs-border-color);
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: var(--bs-secondary-bg);
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: var(--theme) !important;
    --bs-pagination-active-border-color: var(--theme) !important;
    --bs-pagination-disabled-color: var(--bs-secondary-color);
    --bs-pagination-disabled-bg: var(--bs-secondary-bg);
    --bs-pagination-disabled-border-color: var(--bs-border-color);
}
.page-link:hover {
    cursor: pointer;
}
/* breadcrumb */
.breadcrumb-item a{
    text-decoration: none !important;
    color: var(--theme);
}
.breadcrumb-item+.breadcrumb-item::before{
    color: #989898 !important;
}
.SideBar{
    background-color: #fff;
}
.SideBar , .SideBar.hide .menu-list , .SideBar.hide .menu-list span{
    transition: all 0.5s ease;
    -webkit-transition:  all 0.5s ease-in-out;
}   
.SideBar.hide {
    width: 50px !important;
    overflow: hidden;
}
.SideBar.show .menu-list span{
    height: 20px;
}
.SideBar.hide .menu-list span:nth-child(1){
    padding-right: 0.75rem !important;
}
.SideBar.hide .menu-list span:nth-child(2){
    opacity: 0;
    display: none;
    overflow: hidden;
}
.SideBar.hide:hover .menu-list span:nth-child(1){
    padding-right: 0rem !important;
}
.SideBar.hide:hover .menu-list span:nth-child(2){
    opacity: 1;
    display: flex;
    overflow: hidden;
}
.SideBar.hide:hover {
    width: 200px !important;
}
.SideBar.show{
    width: 200px !important;
    overflow: hidden;
}
.SideBar.show .menu-list span:nth-child(2){
    padding-right: 0 !important;
}
.SideBar.show .menu-list span:nth-child(2){
    opacity: 1;
    display: flex;
    overflow: hidden;
}
.badge {
    width: 2px;
    height: 2px;
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 5;
    border: 1.5px solid white;
}
.chat-icons.active, .chat-icons.active:hover {
    border: 1px solid var(--theme) !important;
    background-color: #fff !important;
}
.chat-icons:hover{
    background-color: #e7f8ff !important;
}
.list-hover:hover{
    background-color: #c8c8c8;
}
.list-hover.active{
    background-color: var(--theme-light);
}

.bg-secondary-light{
    background-color: #f5f6f8;
}

/* Line clamp */
.line-trim-1{
    display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.line-trim-2{
    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.line-trim-3{
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.line-trim-4{
    display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.line-trim-5{
    display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.line-trim-6{
    display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

/* Line clamp */
.line-trim-h-1{
    display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: horizontal;  
  overflow: hidden;
}
.line-trim-h-2{
    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: horizontal;  
  overflow: hidden;
}
.line-trim-h-3{
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: horizontal;  
  overflow: hidden;
}
.line-trim-h-4{
    display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: horizontal;  
  overflow: hidden;
}
.line-trim-h-5{
    display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: horizontal;  
  overflow: hidden;
}
.line-trim-h-6{
    display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: horizontal;  
  overflow: hidden;
}

.custom-scroll::-webkit-scrollbar{
    width: 4px;
}

.custom-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
background: #888;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
background: #555;
}

.dotted-line {
    display: flex;
    align-items: center;
    text-align: center;
}

.dotted-line::before,
.dotted-line::after {
    content: '';
    flex: 1;
    border-bottom: 1px dotted #000;
    margin: 0 10px;
}

.dashed-line {
    display: flex;
    align-items: center;
    text-align: center;
}

.dashed-line::before,
.dashed-line::after {
    content: '';
    flex: 1;
    border-bottom: 1px dashed #000;
    margin: 0 10px;
}

.w-md-75{
    width: 75% !important;
}

.rounded-message-right{
    border-radius: 4px 0 4px 4px;
}
.rounded-message-left{
    border-radius: 0px 4px 4px 4px;
}
.step{
    display: flex;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 50px;
    background: #fff;
}
.step.active{
    background-color: var(--theme);
}
.steps {
    content: '';
    width: 1px !important;
    height: 150%;
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    right: 44px;
    z-index: -1;
    background: #cccc;
    padding: 0 !important;
}

.height {
    height: 35px;
    padding: .375rem 2.25rem .375rem .75rem;
    line-height: 1.5;
}

.custom-select-box{
    --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    position: relative;
    height: 35px;
}
.custom-select-box .options input[type="search"]{
    width: 100%;
    height: 35px;
    border: none;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    padding: 0.2rem 0.7rem;
    margin-bottom: 0.5rem;
}
.custom-select-box.collapsed .options {
    display: none;
}
.custom-select-box.expand .options {
    display: block;
    padding: 10px;
    max-height: 200px;
    position: absolute;
    top: 35px;
    list-style: none;
    width: 100%;
    left: 0;
    background: #fff;
    overflow-x: auto;
    font-size: .9rem;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    z-index: 5;
}
.custom-select-box.expand .options .option div{
    padding: .2rem;
}
.custom-select-box.expand .options .option div:hover{
    background-color: #e4e4e4;
}
.custom-select-box.expand .options::-webkit-scrollbar{
    width: 4px;
}

.custom-select-box.expand .options::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.custom-select-box.expand .options::-webkit-scrollbar-thumb {
background: #888;
}

/* Handle on hover */
.custom-select-box.expand .options::-webkit-scrollbar-thumb:hover {
background: #555;
}

.custom-select-box .select-input {
    width: 100%;
    height: 100%;
}
.custom-select-box.expand .options .optionGroup{
    list-style: none;
    margin-bottom: 0;
}
.custom-select-box.expand .options>.optionGroup{
    padding-inline-start: 0;
}

@media screen and (max-width: 780px) {
    .w-sm-100{
        width: 100% !important;
    }
    .SideBar.show {
        z-index: 2;
        position: absolute;
    }
    .SideBar.hide {
        z-index: 0;
        position: relative;
    }
}